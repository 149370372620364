<template>
  <div class="userCheck">
    <!-- <hearder-input
      @getUserList="getUserList"
      title="请输入用户微信用户id"
    ></hearder-input> -->
    <template>
      <el-table
        :data="userList"
        style="width: 100%"
        :header-cell-style="headClass"
      >
      <el-table-column fixed  prop="userName" label="昵称" width="100px"></el-table-column>
      <el-table-column prop="wxUserName" label="微信昵称" width="100px"></el-table-column>
      <el-table-column prop="userNum" label="相亲编号" width="100px"></el-table-column>
        <el-table-column prop="wxUserId" label="用户id" width="100px"></el-table-column>
        <el-table-column label="年龄" width="150px">
          <template slot-scope="scope">
            <span>{{ (scope.row.startAge !== '不限' && scope.row.startAge) ? scope.row.startAge + '岁' : scope.row.startAge }}</span>
            <span>--</span>
            <span>{{ (scope.row.endAge !== '不限' && scope.row.endAge) ? scope.row.endAge + '岁' : scope.row.endAge }}</span>
          </template>
        </el-table-column>
        <el-table-column label="婚姻" width="150px">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.mstate.split(',')" :key="index">
              <span>{{ marrigestatus[item] }}</span>
              <span>/</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="身高" width="150px">
          <template slot-scope="scope">
            <span>{{ (scope.row.startHeight !== '不限' && scope.row.startHeight) ? scope.row.startHeight + 'cm' : scope.row.startHeight }}</span>
            <span>--</span>
            <span>{{ (scope.row.endHeight !== '不限' && scope.row.endHeight) ? scope.row.endHeight + 'cm' : scope.row.endHeight }}</span>
          </template>
        </el-table-column>
        <el-table-column label="体型" width="150px">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.bodyType.split(',')" :key="index">
              <span>{{ bodyArr[item] }}</span>
              <span>/</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="homeState" label="居住地" width="150px"></el-table-column>
        <el-table-column prop="location" label="详情地址" width="150px"></el-table-column>
        <el-table-column prop="salary" label="年薪" width="150px">
          <template slot-scope="scope">
            <span>{{ (scope.row.startSalary !== '不限' && scope.row.startSalary) ? scope.row.startSalary + 'w' : scope.row.startSalary }}</span>
            <span>--</span>
            <span>{{ (scope.row.endSalary !== '不限' && scope.row.endSalary) ? scope.row.endSalary + 'w' : scope.row.endSalary }}</span>
          </template>
          <!-- <template slot-scope="scope">
            <span>{{ scope.row.salary ? scope.row.salary + 'w' : scope.row.salary }}</span>
          </template> -->
        </el-table-column>
        <el-table-column prop="education" label="学历" width="150px">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.education.split(',')" :key="index">
              <span>{{ educationArr[item] }}</span>
              <span>/</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="isChild" label="有无子女" width="150px">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.isChild.split(',')" :key="index">
              <span>{{ childrenArr[item] }}</span>
              <span>/</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="expectation" label="希望他是个怎样的人" width="150px"></el-table-column>

        <el-table-column prop="smoke" label="是否抽烟">
          <template slot-scope="scope">
            <span v-if="scope.row.smoke == 0">不限</span>
            <span v-if="scope.row.smoke == 1">不要抽烟</span>
            <span v-if="scope.row.smoke == 2">可以抽烟</span>
          </template>
        </el-table-column>
        <el-table-column prop="userInfoVo.wine" label="是否喝酒">
          <template slot-scope="scope">
            <span v-if="scope.row.wine == 0">不限</span>
            <span v-if="scope.row.wine == 1">不要喝酒</span>
            <span v-if="scope.row.wine == 2">可以喝酒</span>
          </template>
        </el-table-column>

        <el-table-column label="状态">
            <!-- <span>待审核</span> -->
          <template slot-scope="scope">
            <span v-if="scope.row.isExpectation===0">待审核</span>
            <span v-if="scope.row.isExpectation===1">审核成功</span>
            <span v-if="scope.row.isExpectation===-1">审核失败</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200px" align="center">
          <template slot-scope="scope">
            <div class="operation_group">
              <el-button type="primary" @click="handleCheck(scope.row, 1)">通过</el-button>
              <el-button type="primary" @click="handleCheck(scope.row, -1)">不通过</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="pagination">
      <pagination
        :total="total"
        :size="queryInfo.size"
        :page="queryInfo.page"
        @selectSizeChange="selectSizeChange"
        @selectPageChange="selectPageChange"
      ></pagination>
    </div>
  </div>
</template>

<script>
import { request } from "../../API/request";
import hearderInput from "../../components/hearderInput/hearderInput.vue";
import Pagination from "../../components/pagination/pagination.vue";


export default {
  name: '',
  components: { hearderInput, Pagination },

  data () {
    return {
      userList: [],
      queryInfo: {
        page: 1, //当前页数
        size: 5, //一页显示多个条数据
      },
      // 分页的总条数
      total: 0,
      marrigestatus: ['未婚', '分居', '离异无孩', '离异带孩', '离异不带孩', '丧偶', '不限'],
      bodyArr: ['不限', '偏瘦', '苗条', '居中', '微胖', '超胖'],
      educationArr: ['不限', '高中及以下', '本科', '专科', '研究生', '博士'],
      childrenArr: ['不限', '有孩子', '无孩子', '带孩子','不带孩'],
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    // 修改表头的样式
    headClass() {
      return "background:#fff7f7;";
    },
    getUserList (e) {
      request({
        url: "/userInfo/pageUserChoose",
        method: "GET",
        params: {
          page: this.queryInfo.page,
          size: this.queryInfo.size
        },
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          let records = res.data.records
          records.forEach(item => {
            item.principal = ''
          })
          console.log(records);
          this.userList = records
          this.total = res.data.total
        } else {
          this.$message.error("获取数据失败");
        }
      });
    },

    handleCheck (item, status) {
      console.log(item);
      let that = this;
      let text = ''
      if (status === 1) {
        text = '审核通过'
      }
      if (status === -1) {
        text = '审核不通过'
      }
      let params = {
        startAge: item.startAge,
        endAge: item.endAge,
        mstate: item.mstate,
        startHeight: item.startHeight,
        endHeight: item.endHeight,
        bodyType: item.bodyType,
        homeState: item.homeState,
        location: item.location,
        salary: item.salary,
        education: item.education,
        isChild: item.isChild,
        smoke: item.smoke,
        wine: item.wine,
        expectation: item.expectation,
        wxUserId: item.wxUserId,
        isExpectation: status
      }
      console.log(params)
      that
        .$confirm(text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          setTimeout(() => {
            request({
              url: "/userInfo/updateUserChoose",
              method: "POST",
              data: params,
            }).then((res) => {
              if (res.code === 200) {
                that.$message.success("修改审核状态成功！");
                that.getUserList()
              } else {
                this.$message.error(res.message);
              }
            });
          }, 10);
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    
    // 分页中每页显示几条所触发的回调
    selectSizeChange(e) {
      this.queryInfo.size = e;
      this.getUserList();
    },
    // 当前第几页所触发的回调
    selectPageChange(e) {
      this.queryInfo.page = e;
      this.getUserList();
    }
  }
}
</script>

<style scoped lang=less>
.userCheck {
  background-color: #ffffff;
  padding: 24px;
  height: 93%;
  position: relative;
}
.el-table {
  margin-top: 24px;
}
.operation_group {
  color: #155bd4;
  cursor: pointer;
  display: flex;
}
.isTitle {
  font-size: var(--mainSize);
  text-align: center;
  margin-bottom: 12px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  font-size: var(--subSize);
  .leftText {
    width: 100px;
  }
  .upPhotoList {
    position: relative;
    margin: 20px 20px 0 0;
    .close {
      position: absolute;
      top: -12px;
      right: -12px;
      .close_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .cardList {
    display: flex;
    flex-wrap: wrap;
  }
  .video {
    width: 180px;
    height: 300px;
    display: block;
  }
  .avatar-uploader {
    margin-top: 20px;
  }
  .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    &:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      border: 1px dashed #999999;
      font-size: 28px;
      color: #999999;
      width: 105px;
      height: 105px;
      line-height: 105px;
      text-align: center;
    }
  }
}
.avatar {
  width: 105px;
  height: 105px;
  display: block;
}
.btn {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  .affirm {
    background: var(--pink);
    color: var(--white);
  }
}

</style>